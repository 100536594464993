import * as R from 'ramda'
import { curry } from 'ramda'

export const cond = (...conditions) => {
  for (const condition of conditions) {
    if (condition.length === 1) {
      return condition[0]
    } else if (condition[0]) {
      return condition[1]
    }
  }
}

export const isArray = R.is(Array)

export const isBlank = R.either(R.isEmpty, R.isNil)

export const isBoolean = R.is(Boolean)

export const isFunction = x => typeof x === 'function'

export const isNestedArray = R.allPass([isArray, R.complement(R.isEmpty), R.all(isArray)])

export const isNumber = R.both(R.is(Number), R.complement(Number.isNaN))

export const ifElse = curry((condition, consequent, alternative) =>
  condition ? consequent : alternative
)

export const isObject = R.both(R.is(Object), R.complement(R.either(R.is(Array), R.is(Function))))

export const isPresent = R.complement(isBlank)

export const isString = R.is(String)

export const when = curry((condition, value) => (condition ? value : undefined))

export const unless = curry((condition, value) => (condition ? undefined : value))
